import React from "react";
import "./Footer.Style.css";
import { MdLocationOn } from "react-icons/md";
import { BsLinkedin } from "react-icons/bs";
import {
  BiLogoFacebook,
  BiLogoTwitter,
  BiSolidPhoneCall,
} from "react-icons/bi";

function Footer() {
  return (
    <section className="bg-secondary mt-30">
      <div className="overlay1">
        <div className=" bg-secondaryx mt-20 bg-opacity-20 items-center w-full flex flex-wrap justify-between md:justify-between p-4 md:p-8">
          <img
            alt="logo"
            style={{
              height: 100,
              width: 170,
              marginTop: 30,
              paddingBottom: "2rem",
            }}
            src={require("../images/PaceSetter.png")}
            width={80}
          />

          <div className="flex flex-row space-x-6">
            <button>
              <a>
                <BiLogoFacebook size={30} color="#fff" />
              </a>
            </button>
            <button>
              <a>
                <BiLogoTwitter size={30} color="#fff" />
              </a>
            </button>

            <button>
              <a>
                <BsLinkedin size={30} color="#fff" />
              </a>
            </button>
          </div>
        </div>

        <div
          className="w-full flex flex-wrap justify-between md:justify-between p-4 md:p-8"
          style={{ zIndex: 3 }}
        >
          <section
            className="w-full md:w-1/5 mb-4 md:mb-0 flex flex-col "
            style={{ zIndex: 3 }}
          >
            <h1
              className="text-secondary text-lg tracking-wider font-primary 
        text-md font-normal"
            >
              What We Do
            </h1>

            <h1 className="flex flex-col py-3 text-primary text-md">
            Creating enduring residences, constructing lifelong aspirations. Begin your path to extraordinary living with us.
            </h1>
          </section>

          <section className="w-full md:w-1/5 mb-4 md:mb-0 flex flex-col ">
            <h1
              className="text-secondary text-lg tracking-wider font-primary 
                    text-md font-normal"
            >
              Quick Links
            </h1>

            <div className="flex flex-col py-3" style={{ zIndex: 999 }}>
              <a
                href="/about"
                className=" text-md text-primary py-1 font-primary"
              >
                About Us
              </a>
              <a
                href="/service"
                className="text-primary text-md py-1 font-primary"
              >
                Services
              </a>

              <a
                href="/vision"
                className="text-primary text-md py-1 font-primary"
              >
                Vision
              </a>
              <a
                href="/contact"
                className="text-primary text-md py-1 font-primary"
              >
                Talk to Us
              </a>
            </div>
          </section>

          <section className="w-full md:w-1/5 mb-4 md:mb-0 flex flex-col ">
            <h1
              className="text-secondary tracking-wider text-lg font-primary 
                    text-md font-normal"
            >
              Contact Us
            </h1>

            <div className="flex flex-col py-3">
              <a className="text-primary  my-5 text-left text-md">
              13 Holyrood House, London N4 2QD
              </a>

              {/* <a className="text-primary  my-5 text-left text-lg">
                +441582455553
              </a> */}
            </div>
          </section>
        </div>

        <div className="bg-primary  border-t-[0.2px] border-secondary items-center w-full flex flex-wrap justify-between md:justify-between p-4 md:p-8">
          <span className="text-secondary text-xs my-2">
            {" "}
            Pace Setter. &copy; 2023 All Right Reserved
          </span>

          <div className="flex flex-row space-x-6">
            <button>
              <h1 className="text-secondary">Terms of our Services</h1>
            </button>
            <div className="h-6 w-[0.5px] bg-secondary"></div>
            <button>
              <h1 className="text-secondary">Privacy & Policy</h1>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
