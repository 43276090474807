import { Route, Routes } from "react-router-dom";
import SharedLayout from "./components/SharedLayout";
import Error from "./components/Error";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Service from "./pages/Service";
import Contact from "./pages/Contact";

const App = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Routes>
    <Route path="/" element={<SharedLayout/>}>
      <Route index element={< Home />} />
      <Route path={'/about'} element={<About />} />
      <Route path={'/service'} element={< Service />} />
      <Route path={'/contact'} element={<Contact/>} />
    </Route>
    <Route path={'*'} element={<Error />} />
  </Routes>
  )
}

export default App;

