import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <header className="sticky top-0 z-50 bg-primary">
      <nav className="flex items-center justify-between w-full px-8 py-0 ">
        <div className="z-50 flex items-center justify-between w-full md:w-auto ">
          <img
            alt="logo"
            style={{
              height: 110,
              width: 210,
              marginTop: 30,
              paddingBottom: "2rem",
            }}
            src={require("../images/PaceSetter.png")}
            width={80}
          />
          <div className="md:hidden" onClick={() => setOpen((open) => !open)}>
            {open ? (
              <AiOutlineClose
                size={24}
                className=" p-1 bg-[#fff] "
                color="#27374d"
              />
            ) : (
              <AiOutlineMenu color="#c3acd0" size={30} />
            )}
          </div>
        </div>

        <div className="hidden gap-8 md:flex">
          <NavLink
            to={"/"}
            style={({ isActive }) =>
              isActive
                ? {
                    color: "#FFFBF5",
                    fontWeight: "500",
                    fontSize: 17,
                    borderBottom: 1,
                    borderColor: "#c3acd0",
                    borderWidth: 1,
                    padding: 12,
                  }
                : { color: "#C3ACD0", fontWeight: "500", fontSize: 17 }
            }
          >
            Pace Setter
          </NavLink>

          <NavLink
            to={"/about"}
            style={({ isActive }) =>
              isActive
                ? {
                    color: "#FFFBF5",
                    fontWeight: "500",
                    fontSize: 17,
                    borderBottom: 1,
                    borderColor: "#c3acd0",
                    borderWidth: 1,
                    padding: 12,
                  }
                : { color: "#C3ACD0", fontWeight: "500", fontSize: 17 }
            }
          >
            About Us
          </NavLink>

          <NavLink
            to={"/service"}
            style={({ isActive }) =>
              isActive
                ? {
                    color: "#FFFBF5",
                    fontWeight: "500",
                    fontSize: 17,
                    borderBottom: 1,
                    borderColor: "#c3acd0",
                    borderWidth: 1,
                    padding: 12,
                  }
                : { color: "#C3ACD0", fontWeight: "500", fontSize: 17 }
            }
          >
            Services
          </NavLink>

          <NavLink
            to={"/contact"}
            style={({ isActive }) =>
              isActive
                ? {
                    color: "#FFFBF5",
                    fontWeight: "500",
                    fontSize: 17,
                    borderBottom: 1,
                    borderColor: "#c3acd0",
                    borderWidth: 1,
                    padding: 12,
                  }
                : { color: "#C3ACD0", fontWeight: "500", fontSize: 17 }
            }
          >
            Contact Us
          </NavLink>
        </div>

        {/* mobile nav */}
        <div
          className={`flex flex-col md:hidden py-24 absolute top-0 mt-20 gap-8 px-10 w-full ${
            open ? " bg-primary " : "bg-transparent"
          } h-[fit-content] duration-500 ${
            open ? "left-0" : "-left-full"
          } transition-all`}
        >
          <NavLink
            className="mt-10"
            reloadDocument
            to={"/"}
            style={({ isActive }) =>
              isActive
                ? {
                    color: "#Fff",
                    fontWeight: "600",
                    marginTop: 20,
                    fontSize: 12,
                    zIndex: 1,
                  }
                : {
                    color: "#C3ACD0",
                    fontWeight: "600",
                    marginTop: 20,
                    fontSize: 12,
                    zIndex: 1,
                  }
            }
          >
            Pace Setter
          </NavLink>

          <NavLink
            to={"/about"}
            reloadDocument
            style={({ isActive }) =>
              isActive
                ? { color: "#Fff", fontWeight: "500", fontSize: 10, zIndex: 1 }
                : {
                    color: "#C3ACD0",
                    fontWeight: "500",
                    fontSize: 10,
                    zIndex: 1,
                  }
            }
          >
            About Us
          </NavLink>

          <NavLink
            to={"/service"}
            reloadDocument
            style={({ isActive }) =>
              isActive
                ? { color: "#Fff", fontWeight: "500", fontSize: 10, zIndex: 1 }
                : {
                    color: "#C3ACD0",
                    fontWeight: "500",
                    fontSize: 10,
                    zIndex: 1,
                  }
            }
          >
            Services
          </NavLink>

          <NavLink
            reloadDocument
            to={"/contact"}
            style={({ isActive }) =>
              isActive
                ? { color: "#Fff", fontSize: 12, zIndex: 1 }
                : { color: "#C3ACD0", fontSize: 12, zIndex: 1 }
            }
          >
            Contact Us
          </NavLink>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
