import React from "react";
import Form from "../components/Form";
import { MdCall, MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { AiOutlineArrowDown } from "react-icons/ai";

const Contact = () => {
  return (
    <main className="min-h-screen bg-white mb-20">
      
      <main className="contact">
        <div className="flex flex-col items-center" style={{ zIndex: 3 }}>
          <div className="flex flex-col my-1 items-center">
            {/* <MdCall className="text-secondary mt-1" size={20} color="#fff" /> */}
            {/* <h1 className="text-[#fff] text-md">+44 7852 199849</h1> */}
          </div>

          <div className="flex flex-col my-1 items-center">
            {/* <MdEmail className="text-secondary mt-1" size={20} color="#fff" /> */}
            {/* <h1 className="text-[#fff] text-md">
              sandra@angelraysconsultancy.org
            </h1> */}
          </div>

          <div className="flex flex-col my-1 items-center">
            <ImLocation2
              className="text-secondary mt-1"
              size={20}
              color="#fff"
            />
            <a href="/" className="text-primaryx text-center px-5 text-md">
            13 Holyrood House, London N4 2QD
            </a>
          </div>
        </div>
      </main>

      <div className="flex flex-row text-center justify-center my-5 mt-20 items-center">
        <h1 className="text-primary px-3 text-lg">Write to US on Email</h1>

        <AiOutlineArrowDown
          className="text-secondary mt-1"
          size={20}
          color="#000"
        />
      </div>

      <div className="p-3 mt-10 items-center flex flex-col md:flex-row md:justify-evenly  items-center">
        <section className="p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <div
            className=""
            // data-aos="zoom-out-up"
          >
            <Form />
          </div>
        </section>

        <section className="p-3 m-5 border-primary w-full md:w-1/2 md:mb-0 flex flex-col">
          <img
            style={{ borderRadius: 16 }}
            src={require("../images/contact_us.png")}
          />
        </section>
      </div>

      <script src="/js"></script>
    </main>
  );
};

export default Contact;
