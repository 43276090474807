import React, { useEffect } from "react";
import { MdOutlineNavigateNext } from "react-icons/md";
import AOS from "aos";
import "aos/dist/aos.css";
import { IoIosCheckmarkCircle } from "react-icons/io";

function Service() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); // R

  AOS.init({
    duration: 800, // Animation duration (in milliseconds)
    offset: 100, // Offset (in pixels) from the original trigger point
    easing: "ease-in-out", // Easing function for the animation
    once: false, // Only animate elements once while scrolling down
  });

  return (
    <main className="bg-secondary  lg:px-30 md:px-30 bg-opacity-20">
      <main className="service ">
        <div className="flex flex-col items-center" style={{ zIndex: 3 }}>
          <h1 className="text-[#fff] text-2xl">Our Services</h1>

          <div className="flex flex-row items-center">
            <a href="/" className="text-primaryx  text-sm">
            Pace Setter
            </a>
            <MdOutlineNavigateNext
              className="text-secondary mt-1"
              size={20}
              color="#fff"
            />
            <a href="angelrays" className="text-primaryx  text-sm">
              Services
            </a>
          </div>
        </div>
      </main>

      <section
        data-aos="fade-in"
        className="mb-21 bg-[#ffffff] p-5 flex lg:p-10 flex-col mx-5 lg:flex-row my-10 items-center justify-evenly lg:space-x-10"
      >
        <div data-aos="zoom-in">
          <img
            style={{ width: 1800 }}
            className="rounded-lg mt-5 "
            src={require("../images/young-man-working-his-laptop-hotel-room.jpg")}
            alt="Support Image"
          />
        </div>
        <div className="text-left">
          <h1 className="text-2xl  text-primary my-5 font-bold font-concert">
            ACCOMMODATION
          </h1>
          <div className="w-20 h-[0.5px] bg-primary mb-5"></div>

          <p className="text-gray text-justify text-md my-5">
            We're dedicated to presenting a diverse array of comfortable and
            secure lodging options meticulously crafted to suit the distinct and
            varied needs of our residents. Our foremost goal is to ensure that
            every resident not only finds a place to live but also experiences a
            profound sense of belonging, safety, and tranquility within their
            living space.
          </p>

          <p className="text-gray text-justify text-md my-5">
            Be it snug single-bedroom units, expansive apartments, or
            purpose-built suites, our aim is to offer a spectrum of choices that
            cater specifically to the preferences and requirements of our
            residents. Our commitment to delivering exceptional accommodations
            is rooted in our unwavering pledge to elevate the overall quality of
            life for everyone in our care.
          </p>
        </div>
      </section>

      <section
        data-aos="fade-in"
        className="mb-20 bg-[#ffffff] p-5 flex lg:p-10 flex-col mx-5 lg:flex-row my-10 items-center justify-evenly lg:space-x-10"
      >
        <div data-aos="zoom-in">
          <img
            className="rounded-lg mt-5 "
            src={require("../images/trainer-explaining-software-specifics-new-employee.jpg")}
            alt="Support Image"
          />
        </div>
        <div className="text-left">
          <h1 className="text-2xl  text-primary my-5 font-bold font-concert">
            SUPPORT SERVICES
          </h1>
          <div className="w-20 h-[0.5px] bg-primary mb-5"></div>

          <p className="text-gray text-justify text-md my-5">
            Our devoted team of adept professionals surpasses expectations by
            delivering personalized support services, tailoring their approach
            to meet the distinct aspirations and goals of our residents. We
            firmly believe in empowering individuals to achieve their utmost
            potential, hence offering an extensive range of services. These
            encompass educational and career support, where we guide and assist
            individuals in pursuing their academic ambitions and career
            objectives through tailored resources and guidance. Additionally,
            our residents benefit from programs focused on life skills training,
            equipping them with essential abilities for daily living, fostering
            independence and self-reliance.
          </p>

          <p className="text-gray text-justify text-md my-5">
            Recognizing the significance of emotional well-being in the path to
            personal growth, our staff members undergo training to provide
            empathetic emotional support. This creates a nurturing environment
            where residents feel understood, appreciated, and motivated. Through
            the cultivation of a supportive community and the provision of
            personalized aid, our goal is to inspire and empower residents to
            surmount obstacles, achieve their aspirations, and lead fulfilling
            lives.
          </p>
        </div>
      </section>

      <section
        data-aos="fade-in"
        className="mb-20 bg-[#ffffff] p-5 flex lg:p-10 flex-col mx-5 lg:flex-row my-10 items-center justify-evenly lg:space-x-10"
      >
        <div data-aos="zoom-in">
          <img
            className="rounded-lg mt-5 "
            src={require("../images/young-people-playing-table-tennis-workplace-having-fun.jpg")}
            alt="Support Image"
          />
        </div>
        <div className="text-left">
          <h1 className="text-2xl  text-primary my-5 font-bold font-concert">
            RECREATIONAL ACTIVITIES
          </h1>
          <div className="w-20 h-[0.5px] bg-primary mb-5"></div>

          <p className="text-gray text-justify text-md my-5">
            Understanding the importance of fostering happiness and physical
            well-being, we prioritize the promotion of engaging in recreational
            activities. That's why we've carefully assembled a diverse array of
            activities aimed at ensuring our residents not only enjoy themselves
            but also remain active and involved. Residents have the chance to
            take part in a range of recreational pursuits, encompassing sports
            that promote physical fitness and teamwork, arts and crafts sessions
            fostering creativity and self-expression, and tranquil movie nights
            that offer entertainment and camaraderie.
          </p>

          <p className="text-gray text-justify text-md my-5">
            Through offering this extensive range of recreational choices, our
            goal is to cultivate a vibrant and stimulating community where
            residents can delve into their passions, forge meaningful
            connections, and uphold an active and fulfilling lifestyle. We
            strongly believe that these activities not only bring happiness and
            enjoyment but also play a crucial role in the overall well-being of
            our residents. They enhance quality of life and foster a profound
            sense of belonging within our community.
          </p>
        </div>
      </section>

      <section
        data-aos="fade-in"
        className="mb-20 bg-[#ffffff] p-5 flex lg:p-10 mb-40 flex-col mx-5 lg:flex-row my-10 items-center justify-evenly lg:space-x-10"
      >
        <div data-aos="zoom-in">
          <img
            className="rounded-lg mt-5 "
            src={require("../images/front-view-interracial-friends-cheering.jpg")}
            alt="Support Image"
          />
        </div>
        <div className="text-left">
          <h1 className="text-2xl  text-primary my-5 font-bold font-concert">
            COMMUNITY INVOLVEMENT
          </h1>
          <div className="w-20 h-[0.5px] bg-primary mb-5"></div>

          <p className="text-gray text-justify text-md my-5">
            Within our facility, we place great emphasis on community
            involvement and actively motivate our residents to engage with the
            local community. We strongly believe in the transformative impact of
            giving back, and to enable this, we offer a variety of avenues for
            our residents to volunteer and make meaningful contributions.
          </p>

          <p className="text-gray text-justify text-md my-5">
            We present a spectrum of volunteer programs and initiatives,
            enabling our residents to invest their time and skills in charitable
            causes, community events, and social projects. Engaging in these
            activities allows our residents not only to create a positive
            influence in the lives of others but also to discover a deep sense
            of fulfillment and purpose. Through volunteering, they gain
            opportunities to acquire new skills, foster social connections, and
            expand their perspectives, all while making a meaningful impact on
            the lives of those in need.
          </p>
        </div>
      </section>
    </main>
  );
}

export default Service;
