import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './Navbar';
import Footer from '../pages/Footer';
// import Header from '../pages/Header';


const SharedLayout = () => {
  return (
    <>
        {/* <Header /> */}
        <Navbar />
        <Outlet />
        <Footer />
    </>
  )
}

export default SharedLayout;
