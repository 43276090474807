import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";

function Home() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []); // R

  AOS.init({
    duration: 800, // Animation duration (in milliseconds)
    offset: 100, // Offset (in pixels) from the original trigger point
    easing: "ease-in-out", // Easing function for the animation
    once: false, // Only animate elements once while scrolling down
  });

  return (
    <main>
      <Helmet>
        <title> Pace Setter</title>
        <meta
          name="description"
          content="Crafting Timeless Homes, Building Lifelong Dreams. Your Journey to Exceptional Living Begins Here."
        />
        <link rel="canonical" href="/" />
      </Helmet>

      <div class="homebanner">
        <div class="overlay">
          <h1 className="p-3 text-center text-primary font-concert lg:text-6xl font-bold text-4xl ">
            Pace Setter
          </h1>

          <p className="text-center text-secondaryx px-5">
           Your well-being, Our Priority
          </p>

          <a href="/service">
            <button className="bg-primary bg-opacity-70 text-sm h-14 w-40 mt-20 shadow rounded-full text-[#fff] my-5 hover:bg-secondary hover:shadow-md transition duration-300 animate-bounce ease-in-out">
              Our Services
            </button>
          </a>
        </div>
      </div>

      <section
        data-aos="fade-in"
        className="mb-20 bg-[#ffffff] flex lg:p-10 space-y-10 flex-col mx-5 my-10 lg:flex-row items-center justify-evenly lg:space-x-10"
      >
        <img
          className="rounded-[10px] "
          src={require("../images/happy-african-american-young-family-bought-new-house.jpg")}
        />

        <div>
          <span className="font-roboto text-primary font-normal text-3xl my-3">
            {" "}
            Pace Setter:
          </span>
          <p className="my-5 text-justify text-gray">
           A Haven for Young
            Adults At Pace Setter, we specialize in providing supportive
            accommodation for individuals aged 16 and above. Our dedicated
            mission is to cultivate a secure and nurturing environment that
            empowers young individuals to unlock their fullest potential.
          </p>

          <p className="my-5 text-justify text-gray">
            We understand that the journey into adulthood can be challenging,
            particularly for those who have encountered trauma or difficult
            circumstances. This is why Pace Setter goes the extra mile, offering
            a range of comprehensive services and programs aimed at enabling our
            residents not just to succeed, but to thrive.
          </p>

          <p className="my-5 text-justify text-gray">
            Explore a place where young hearts and minds are encouraged to
            flourish, where hope brightens futures. Choose Pace Setter for the
            transformative journey into young adulthood.
          </p>

          <a href="/service">
            <button className="bg-primary text-sm h-14 w-40 my-5 shadow rounded-full text-[#fff] my-5 hover:bg-secondary hover:shadow-md transition duration-300 ease-in-out">
              Read More
            </button>
          </a>
        </div>
      </section>

      <div class="home-service">
        <div class="home-service-overlay">
          <div className="w-36 h-[0.3px] bg-primary"></div>
          <p className="text-center my-5 text-primary text-right px-5">
            At Pace Setter, our devoted team of skilled professionals is
            wholeheartedly committed to crafting a warm and inviting environment
            that residents can truly call home. We prioritize creating a sense
            of belonging, where every individual feels valued and fully
            supported in their unique journey. In pursuit of this mission, we
            offer an extensive range of services tailored to cater to the
            diverse needs of our residents.
          </p>
          <div className="w-36 h-[0.3px] bg-primary"></div>

          <p className="my-5 text-primary text-left px-5">
            Our comprehensive offerings at Pace Setter encompass various facets,
            including health, wellness, and personal care, ensuring that each
            resident receives the dedicated attention and assistance they
            deserve. Through fostering a nurturing atmosphere, our goal is to
            elevate the quality of life for everyone under our care, delivering
            not only services but also fostering a genuine sense of community
            and belonging.
          </p>

          <div className="w-36 h-[0.3px] bg-primary"></div>
        </div>
      </div>

      <section
        data-aos="fade-in"
        className="mb-20 bg-[#ffffff] flex lg:p-10 space-y-10 mb-40 flex-col mx-5 my-10 lg:flex-row items-center justify-evenly lg:space-x-10"
      >
        <div>
          <span className="font-roboto text-primary font-normal text-3xl my-3">
            {" "}
            Why Pace Setter ?
          </span>
          <p className="my-5 text-justify text-gray">
            Nestled within the embrace of compassion and care, Pace Setter
            stands as a sanctuary for young adults, a beacon of hope and support
            for individuals aged 16 and above. Our commitment transcends mere
            accommodation; we are devoted to nurturing the spirits and
            aspirations of young people, guiding them through the intricate
            journey of transitioning into adulthood.
          </p>

          <p className="my-5 text-justify text-gray">
            In a world that often feels overwhelming, Pace Setter emerges as an
            unwavering pillar of strength. Our mission is resolute: to forge a
            secure and nurturing environment where every resident can flourish
            and actualize their fullest potential. We understand the daunting
            path to adulthood, particularly for those who have endured adversity
            and trauma. That's why we transcend mere shelter, offering a
            comprehensive array of meticulously crafted services and programs
            aimed at empowering our residents.
          </p>

          <p className="my-5 text-justify text-gray"></p>

          <a href="/vision">
            <button className="bg-primary text-sm h-14 w-40 my-5 shadow rounded-full text-[#fff] my-5 hover:bg-secondary hover:shadow-md transition duration-300 ease-in-out">
              Our Vision
            </button>
          </a>
        </div>

        <img
          className="rounded-[10px] "
          src={require("../images/young-cheerful-family-during-quarantine-insulation-spending-time-together-home.jpg")}
        />
      </section>

      <section
        data-aos="fade-in"
        className="mb-20 bg-[#ffffff] flex lg:p-10 space-y-10 mb-40 flex-col mx-5 my-10 lg:flex-row items-center justify-evenly lg:space-x-10"
      >
        <div>
          <span className="font-roboto text-primary font-normal text-3xl my-3">
            {" "}
            Why People Choose Pace Setter
          </span>

          <p className="my-5 text-justify text-gray">
            Selecting Pace Setter signifies embracing a transformative journey
            into young adulthood, where every step is nurtured with care, every
            challenge met with resilience, and every accomplishment celebrated
            with pride. Join us in this empowering expedition, and together,
            let's illuminate the futures of these young spirits, illuminating
            the path toward a brighter tomorrow.
          </p>

          <p className="my-5 text-justify text-gray"></p>
        </div>

        <img
          className="rounded-[10px] "
          src={require("../images/house1.jpg")}
        />
      </section>
    </main>
  );
}

export default Home;
