import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
};

function About() {
  const houseImages = [
    "https://via.placeholder.com/800x400?text=House+1",
    "https://via.placeholder.com/800x400?text=House+2",
    "https://via.placeholder.com/800x400?text=House+3",
    "https://via.placeholder.com/800x400?text=House+4",
    "https://via.placeholder.com/800x400?text=House+5",
    "https://via.placeholder.com/800x400?text=House+6",
    "https://via.placeholder.com/800x400?text=House+7",
    // Add more placeholder URLs as needed
  ];

  return (
    <div>
      <div class="about-content">
        <div class="about-overlay">
          <div className="w-36 h-[0.3px] bg-primary"></div>
          <p className="text-center my-5 text-primary text-right px-5">
            In our sanctuary, Pace Setter specializes in curating a haven
            exclusively for young adults aged 16 and above. Within this
            nurturing environment, we prioritize not only lodging but also a
            supportive and compassionate setting tailored to meet the
            distinctive requirements of young individuals venturing into
            adulthood.
          </p>
          <div className="w-36 h-[0.3px] bg-primary"></div>

          <p className="my-5 text-primary text-left px-5">
            Our steadfast commitment revolves around establishing a secure and
            nurturing space where young hearts and minds thrive, enabling them
            to realize their utmost potential. Acknowledging the challenges that
            come with transitioning to adulthood, particularly for those who
            have encountered trauma or adverse circumstances, Pace Setter
            distinguishes itself by exceeding expectations. We meticulously
            craft a comprehensive array of services and programs aimed not just
            at enabling our residents to succeed but to truly flourish.
          </p>

          <div className="w-36 h-[0.3px] bg-primary"></div>

          <p className="my-5 text-primary text-right px-5">
            At Pace Setter, we offer beyond mere accommodation; we foster a
            transformative experience that illuminates futures with optimism.
            Our sanctuary serves as a platform for encouraging young individuals
            to envision grand aspirations, embrace their passions, and construct
            the groundwork for a prosperous and rewarding life ahead. Come join
            Pace Setter and commence a transformative journey into young
            adulthood, where opportunities abound, and aspirations are nurtured
            into reality.
          </p>
        </div>
      </div>

      <div className="p-10 mb-16">
        <Slider {...settings}>
          <div>
            <img
              src={require("../images/3d-rendering-loft-luxury-living-room-with-bookshelf-near-bookshelf.jpg")}
              alt="Image 1"
            />
          </div>
          <div>
            <img src={require("../images/house1.jpg")} alt="Image 1" />
          </div>

          <div>
            <img src={require("../images/house5.jpg")} alt="Image 1" />
          </div>

          <div>
            <img src={require("../images/house7.jpg")} alt="Image 1" />
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default About;
